<template>
  <div class="space-y-4">
    <article v-if="createInjuryIncidents">
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section
        class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Use First Aid Items',
            })
          "
          text="Report Injury Incident"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card
      v-if="!isLoading"
    >
  
      <asom-client-table
        :columns="[
          'description',
          'createdBy',
          'date',
          'time',
        ]"
        :data="firstAidFormList"
        :filterable="false"
      >
        <template v-slot:description="scopedSlots">
          <button
            @click="navigateToView(scopedSlots.rowData)"
            class="text-button underline font-semibold"
          >
            {{ scopedSlots.data }}
          </button>
        </template>
        <template v-slot:header_createdBy>Reported By</template>
        <template v-slot:date="scopedSlots">
          <span>{{
            displayUtcDate(get(scopedSlots.rowData, "dateCreated"))
          }}</span>
        </template>
        <template v-slot:time="scopedSlots">
          <span>{{
            displayUtcTime(get(scopedSlots.rowData, "dateCreated"))
          }}</span>
        </template>
      </asom-client-table>
    </asom-card>
    <div v-else class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import {
  displayUtcDate,
  displayUtcTime,
} from "@/helpers/dateTimeHelpers";
import { getAsync } from "@/services/serviceHandlers";
import { FIRST_AID_FORM_LIST } from "@/constants/apis";

export default {
  name: "FirstAidMainPage",
  data() {
    return {
      isLoading: false,
      error: null,
      firstAidFormList: [],
    };
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      createInjuryIncidents: "auth/createInjuryIncidents",
      viewInjuryIncidents: "auth/viewInjuryIncidents",
      isOnline: "apiStatus/isInventoryModuleOnline",
      lineId: "auth/userLineId",
    }),
  },
  mounted() {
    if (this.stationId !== null) {
      this.getPageData();
    }
  },
  methods: {
    get,
    displayUtcDate,
    displayUtcTime,
    navigateToView(selectedItem) {
      this.$router.push({
        name: "View First Aid Log",
        params: selectedItem,
      });
    },
    async getPageData() {
      this.isLoading = true;
      const resp = await getAsync({
        url: FIRST_AID_FORM_LIST,
        params: {
          skip: 0,
          take: 100,
          stationId: this.stationId,
          lineId: this.lineId,
        }
      });

      if (!resp.success) {
        this.error = resp.payload;
        this.$scrollTop();
      } else {
        this.error = null;
        this.firstAidFormList = get(resp, 'payload.list');
      }
      this.isLoading = false;
    },
  },
};
</script>
